import React from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "components/layout/PageError";
import AccessControl from "pages/authorized/settings/AccessControl";
import Customer from "pages/authorized/customer/index";
import Region from "../../pages/authorized/settings/Region";
import Product from "../../pages/authorized/settings/Product";
import Collector from "../../pages/authorized/collector/index";
import Transaction from "../../pages/authorized/transaction/index";
import Report from "../../pages/authorized/report/index";
import CollectorData from "../../pages/authorized/collector/Data";
import UserData from "../../pages/authorized/customer/Data";
import PushNotification from "../../pages/authorized/settings/PushNotification";
import Collection from "../../pages/authorized/general/Collection";
import Media from "../../pages/authorized/other/Media";
import LiveTrack from "../../pages/authorized/general/LiveTrack";
import Contribution from "../../pages/authorized/general/Contribution";
import Redeem from "../../pages/authorized/general/Redeem";
import Merchant from "../../pages/authorized/merchant";
import Membership from "../../pages/authorized/general/Membership";

const Private = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/customer" component={Customer} />
            <PrivateRoute exact path="/contribution" component={Contribution} />
            <PrivateRoute exact path="/redeem" component={Redeem} />
            <PrivateRoute exact path="/membership" component={Membership} />
            <PrivateRoute exact path="/merchant" component={Merchant} />
            <PrivateRoute exact path="/collection" component={Collection} />
            <PrivateRoute exact path="/transaction" component={Transaction} />
            <PrivateRoute exact path="/error" component={PageError} />

            <PrivateRoute exact path="/region" component={Region} />
            <PrivateRoute exact path="/product" component={Product} />
            <PrivateRoute exact path="/report" component={Report} />
            <PrivateRoute exact path="/collector/:id" component={CollectorData} />
            <PrivateRoute exact path="/user/:id" component={UserData} />
            <PrivateRoute exact path="/notification" component={PushNotification} />
            <PrivateRoute exact path="/access_control" component={AccessControl} />
            <PrivateRoute exact path="/media" component={Media} />
            <PrivateRoute exact path="/track" component={LiveTrack} />
            <Route component={PageError} />
        </Switch>
    )
};

export default Private;
