import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import feather from "feather-icons";
import {logoutUser} from '../../api/auth';
import PerfectScrollbar from 'perfect-scrollbar';
import {useDispatch, useSelector} from "react-redux";

const Header = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.user);
    const [menu, set_menu] = useState({
        customer: "nav-item",
        contribution: "nav-item",
        redeem: "nav-item",
        transaction: "nav-item",
        product: "nav-item",
        report: "nav-item",
        merchant: "nav-item",
        access_control: "nav-item"
    });

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    //detect url change and update it.
    useEffect(() => {
        let url = window.location.hash.split("/").pop();
        Object.keys(menu).forEach(function(key){ set_menu((prevState) => ({
            ...prevState,
            [key]: "nav-item",
            [url]: "nav-item active",
            ["loan_sub"]: "nav-item with-sub"
        }))});
    }, [window.location.hash.split("/").pop()])

    useEffect(() => {
        $(function () {
            'use strict';
            feather.replace();

            const asideBody = new PerfectScrollbar('.aside-body', {
                suppressScrollX: true
            });

            if($('.aside-backdrop').length === 0) {
                $('body').append('<div class="aside-backdrop"></div>');
            }

            var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)');

            function doMinimize(e) {
                if (e.matches) {
                    $('.aside').addClass('minimize');
                } else {
                    $('.aside').removeClass('minimize');
                }

                asideBody.update()
            }

            mql.addListener(doMinimize);
            doMinimize(mql);

            $('.aside-menu-link').on('click', function(e){
                e.preventDefault();

                if(window.matchMedia('(min-width: 992px)').matches) {
                    $(this).closest('.aside').toggleClass('minimize');
                } else {

                    $('body').toggleClass('show-aside');
                }

                asideBody.update()
            });

            $('.nav-aside .with-sub').on('click', '.nav-link', function(e){
                e.preventDefault();

                $(this).parent().siblings().removeClass('show');
                $(this).parent().toggleClass('show');

                asideBody.update()
            });

            $('body').on('mouseenter', '.minimize .aside-body', function(e){
                console.log('e');
                $(this).parent().addClass('maximize');
            });

            $('body').on('mouseleave', '.minimize .aside-body', function(e){
                $(this).parent().removeClass('maximize');

                asideBody.update()
            });

            $('body').on('click', '.aside-backdrop', function(e){
                $('body').removeClass('show-aside');
            })
        });
    }, []);

    return (
        <aside className="aside aside-fixed">
            <div className="aside-header">
                <a href="#" className="aside-logo"><img src="assets/img/header_logo.png" className="ht-45 img-fluid" alt="logo"/></a>
                <a href="#" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">
                <div className="aside-loggedin">
                    <div className="aside-loggedin-user">
                        <a href="#loggedinMenu" className="d-flex align-items-center justify-content-between mg-b-2"
                           data-toggle="collapse">
                            <h6 className="tx-semibold mg-b-0">{auth.user.name}</h6>
                            <i data-feather="chevron-down"></i>
                        </a>
                        <p className="tx-color-03 tx-12 mg-b-0">{auth.user.role}</p>
                    </div>
                    <div className="collapse" id="loggedinMenu">
                        <ul className="nav nav-aside mg-b-0">
                            <li className="nav-item"><Link to="#" onClick={onLogoutClick.bind()} className="nav-link"><i data-feather="log-out"></i>
                                <span>Sign Out</span></Link></li>
                        </ul>
                    </div>
                </div>

                <ul className="nav nav-aside">
                    <li className="nav-label">General</li>
                    <li className={menu.customer}><Link to="/customer" className="nav-link"><i data-feather="user"></i> <span>Customers</span></Link></li>
                    {/*<li className={menu.contribution}><Link to="/contribution" className="nav-link"><i data-feather="box"></i> <span>Contributions</span></Link></li>*/}
                    <li className={menu.redeem}><Link to="/redeem" className="nav-link"><i data-feather="share"></i> <span>Redeem</span></Link></li>
                    <li className={menu.membership}><Link to="/membership" className="nav-link"><i data-feather="credit-card"></i> <span>Membership</span></Link></li>
                    {/*<li className={menu.transaction}><Link to="/transaction" className="nav-link"><i data-feather="refresh-cw"></i> <span>Transactions</span></Link></li>*/}
                    {/*<li className={menu.merchant}><Link to="/merchant" className="nav-link"><i data-feather="briefcase"></i> <span>Merchants</span></Link></li>*/}
                    {/*<li className="nav-label mg-t-25">Settings</li>*/}
                    {/*<li className={menu.product}><Link to="/product" className="nav-link"><i data-feather="box"></i> <span>Products</span></Link></li>*/}
                    {/*<li className={menu.notification}><Link to="/notification" className="nav-link"><i data-feather="bell"></i> <span>Push Notification</span></Link></li>*/}
                    {/*<li className={menu.access_control}><Link to="/access_control" className="nav-link"><i data-feather="users"></i> <span>Access Control</span></Link></li>*/}
                </ul>
            </div>
        </aside>
    );
};

export default Header;
