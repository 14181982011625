import React, {useEffect} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {transaction} from "../../../api/app";
import moment from "moment";

const Redeem = () => {

    document.title = "Redeem";

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#td-transaction').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Redeem Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "redeem",
                        fieldname: "name",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.txn_number.toUpperCase();
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `K${data.amount}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.gateway);
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "paid" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "pending" : `<span class="badge badge-secondary tx-white">${capitalize(data.status)}</span>`,
                            "failed" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        return () => {
            table.destroy();
        }

    }, []);

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Redeem</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Redeem</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Redeem</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {e.preventDefault(); $('#td-transaction').DataTable().ajax.reload()}} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-transaction" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Customer</th>
                                        <th className="wd-20p">Amount</th>
                                        <th className="wd-20p">Membership</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Date</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Redeem;
