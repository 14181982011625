import React, { useEffect } from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import cogoToast from "cogo-toast";

const Contribution = (props) => {

    document.title = "Contribution";

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#td-user').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Contribution Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "contribution",
                        fieldname: "full_name",
                        pageSize: data.start,
                        format: 'json',
                        populate: [{
                            path: 'membership',
                            model: 'membership',
                            populate: [
                                    {
                                        path: 'product',
                                        model: 'product'
                                    }
                                ]
                            },
                            {
                                path: 'customer',
                                model: 'customer',
                            }],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.membership.id_number.toUpperCase();
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${capitalize(data.customer.full_name)}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.membership.product.name
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `K${data.meta.amount}`
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        return () => {
            table.destroy();
        }

    }, []);

    const reloadTable = () => {
        $('#td-user').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Contributions</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Contributions</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    {/*<div className="col-sm-6 col-lg-4">*/}
                    {/*    <div className="card card-body">*/}
                    {/*        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Registered Contributions</h6>*/}
                    {/*        <div className="d-flex d-lg-block d-xl-flex align-items-end">*/}
                    {/*            <h3 id="total-users" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>*/}
                    {/*        </div>*/}
                    {/*        <span id="total-gender" className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">*/}
                    {/*    <div className="card card-body">*/}
                    {/*        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Registered Contributions Today</h6>*/}
                    {/*        <div className="d-flex d-lg-block d-xl-flex align-items-end">*/}
                    {/*            <h3 id="daily-active" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>*/}
                    {/*        </div>*/}
                    {/*        <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">*/}
                    {/*    <div className="card card-body">*/}
                    {/*        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Registered Contributions Monthly</h6>*/}
                    {/*        <div className="d-flex d-lg-block d-xl-flex align-items-end">*/}
                    {/*            <h3 id="monthly-active" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>*/}
                    {/*        </div>*/}
                    {/*        <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Customers</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {e.preventDefault(); reloadTable()}} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-user" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">ID Number</th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Membership</th>
                                        <th className="wd-20p">Amount</th>
                                        <th className="wd-20p">Date Created</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-user" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Contribution;
