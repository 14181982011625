import React, {useEffect, useState} from 'react';
import $ from "jquery";
import moment from "moment";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {
    GET_COLLECTION_DATA
} from "../../../api";
import cogoToast from "cogo-toast";
import {getUser, getPreSignedUrl, collector, uploadPhoto} from "../../../api/app";
import {capitalize} from "../../../utils/capitalize";
const Buffer = require('buffer/').Buffer;

const Data = (props) => {

    const token = localStorage.getItem('jwtToken');

    const id = props.match.params.id;
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [logo_image, set_logo_image] = useState(null);
    const [editId, setEditId] = useState(null);
    const [images, setImages] = useState([]);
    const [datatable] = useState({
        contribution: false,
        membership: false,
        redeem: false
    });

    useEffect(() => {
        getUser(id).then((response) => {
            if(!response.error) {
                setUser(response.payload);
                setLoading(false);
            }
        });
    }, []);

    const contributionDatatable = () => {
        if(datatable.contribution) return false;
        datatable.contribution = true;
        let table = $('#contribution_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Contribution Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "contribution",
                        fieldname: "name",
                        filter: {
                            customer: user._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                path: 'transaction',
                                model: 'transaction',
                            },
                            {
                                path: 'membership',
                                model: 'membership',
                                populate: {
                                    path: 'product',
                                    model: 'product',
                                }
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.membership.id_number.toUpperCase();
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.membership.product.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `K${data.meta.amount}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "paid" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "pending" : `<span class="badge badge-secondary tx-white">${capitalize(data.status)}</span>`,
                            "expired" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only">-- No Action --</nav>`
                    },
                    "targets": 5
                }
            ]
        });
    }

    const membershipDatatable = () => {
        if(datatable.membership) return false;
        datatable.membership = true;
        let table = $('#membership_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Membership Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "membership",
                        fieldname: "name",
                        filter: {
                            customer: user._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: "product",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.id_number.toUpperCase();
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.product.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `K${data.product.packages.find((item) => item._id == data.package).fee}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "inactive" : `<span class="badge badge-secondary tx-white">${capitalize(data.status)}</span>`,
                            "cancelled" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                }
            ]
        });
    }

    const redeemDatatable = () => {
        if(datatable.redeem) return false;
        datatable.redeem = true;
        let table = $('#redeem_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Redeem Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "redeem",
                        fieldname: "name",
                        filter: {
                            customer: user._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: "product",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return "0";
                        return data.id_number.toUpperCase();
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return "0";
                        return data.product.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return "0";
                        return `K${data.product.packages.find((item) => item._id == data.package).fee}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "inactive" : `<span class="badge badge-secondary tx-white">${capitalize(data.status)}</span>`,
                            "cancelled" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return "0";
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                }
            ]
        });
    }

    const modalCollectorForm = () => {
        setEditForm(true);
        let collector_select = $("#country-select2");
        collector_select.prop("disabled", true).prop("required", false);

        // let $option = $("<option selected></option>").val(garbageCollector.country._id).text(garbageCollector.country.name);
        // collector_select.append($option).trigger('change');

        // $("#name").val(garbageCollector.name);
        // $("#address").val(garbageCollector.address);
        // $("#email").val(garbageCollector.email);
        // $("#mobile").val(garbageCollector.mobile_number);
        // $("#tax").val(garbageCollector.tax_id);

        $('#collector_form').parsley();
        $("#modalCollector").modal({ backdrop: 'static', focus: false, show: true });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#logo_image').parsley().isValid() !== false) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                set_logo_image(reader.result);
            };
            reader.onerror = () => {
                set_logo_image(null);
            };
        }
    }

    const handleCollectorSubmit = async(e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            id: user._id,
            name : $("#name").val(),
            address: $("#address").val(),
            logo_uri : logo_image !== null ? await submitPhoto() : null,
            email : $("#email").val(),
            mobile_number : $("#mobile").val(),
            tax_id : $("#tax").val()
        }

        collector("put", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                resetForm("modalCollector", "collector_form");
                setUser(result.payload);
            }
        })

        return false;
    }

    const submitPhoto = () => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                    'x-amz-acl': 'public-read'
                }
            };

            const buffer = new Buffer.from(logo_image.replace(/^data:image\/\w+;base64,/, ""), 'base64')

            return uploadPhoto(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.config.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container-fluid pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Collector</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-none d-md-block">
                            {user.gender ? <span className="badge badge-success tx-white">Active</span> : <span className="badge badge-danger tx-white">Inactive</span>}
                        </div>
                    </div>

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div className="media">
                            <div className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center bg-white">
                                {user.photo_uri ? <img src={user.photo_uri} className="img-fit-cover rounded-5" alt="logo" /> : <span className="link-03 lh-0 text-center"><i style={{fontSize: 50}} className="icon ion-md-camera"></i><p className="text-center tx-color-03 mt-2 tx-13">No Photo</p></span>}
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5 text-capitalize">{user.full_name}</h5>
                                <p className="mg-b-3 text-capitalize">
                                    <span className="tx-medium tx-color-02 text-capitalize">{user.gender}</span><br />
                                    Zambia
                                </p>
                                <span className="d-block tx-13 tx-color-03">{user.mobile_number}</span>
                            </div>
                        </div>
                        {/*<div className="d-none d-md-block">*/}
                        {/*    <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={modalCollectorForm.bind()}>*/}
                        {/*        <i className="wd-10 mg-r-5 fa fa-truck"></i> Edit Profile*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> User Info</a></li>
                                        <li className="nav-item"><a href="#contribution" className="nav-link" data-toggle="tab" onClick={contributionDatatable.bind()}><i className="ion-md-locate"/> Contribution</a></li>
                                        <li className="nav-item"><a href="#membership" className="nav-link" data-toggle="tab" onClick={membershipDatatable.bind()}><i className="ion-md-trash"/> Membership</a></li>
                                        <li className="nav-item"><a href="#redeem" className="nav-link" data-toggle="tab" onClick={redeemDatatable.bind()}><i className="ion-md-cash"/> Redeem</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Mobile #:</strong> {user.mobile_number}</p>
                                            <p className="text-capitalize"><strong>Gender:</strong> {user.gender}</p>
                                            <p><strong>Country:</strong> Zambia</p>
                                            <p><strong>Device:</strong> {capitalize(user.device.mobile.brand_name)} {capitalize(user.device.mobile.model)}, OS Version {capitalize(user.device.mobile.version)}</p>
                                            <p><strong>Last Login:</strong> {moment(user.last_login.str).format("Do MMM, YYYY")}</p>
                                            <p><strong>Date Created:</strong> {moment(user.date.str).format("Do MMM, YYYY")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="contribution" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Contribution</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#waste_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="contribution_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">ID Number</th>
                                                            <th className="wd-20p">Membership</th>
                                                            <th className="wd-15p">Amount</th>
                                                            <th className="wd-10p">Status</th>
                                                            <th className="wd-15p">Date Issued</th>
                                                            <th className="wd-15p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="membership" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Membership</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#membership_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="membership_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">ID Number</th>
                                                            <th className="wd-20p">Name</th>
                                                            <th className="wd-20p">Subscription Fee</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Date Created</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="redeem" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Redeem</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#redeem_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="redeem_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Description</th>
                                                            <th className="wd-20p">Merchant</th>
                                                            <th className="wd-20p">Membership Charge</th>
                                                            <th className="wd-20p">Date</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalCollector" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalCollector", "collector_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Garbage Collector" : "New Garbage Collector"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} garbage collector information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="collector_form" className="parsley-style-1" onSubmit={handleCollectorSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Company Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter company name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="logoWrapper" className="form-group parsley-input">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Company Logo</label>
                                            <input id="logo_image" type="file" className="form-control"
                                                   onChange={handleFileUpload.bind()}
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="image/jpeg, image/jpg, image/png"
                                                   accept="image/jpeg, image/jpg, image/png"
                                                   data-parsley-class-handler="#logoWrapper"
                                            />
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="mobileWrapper" className="form-group parsley-input">
                                            <label>Mobile Number<span className="tx-danger">*</span></label>
                                            <input id="mobile" type="number" className="form-control"
                                                   placeholder="Enter mobile number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#mobileWrapper" required/>
                                        </div>

                                        <div id="addressWrapper" className="form-group parsley-input">
                                            <label>Physical Address<span className="tx-danger">*</span></label>
                                            <input id="address" type="text" className="form-control"
                                                   placeholder="Enter physical address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#addressWrapper" required/>
                                        </div>

                                        <div id="taxWrapper" className="form-group parsley-input">
                                            <label>Tax ID<span className="tx-danger">*</span></label>
                                            <input id="tax" type="text" className="form-control"
                                                   placeholder="Enter tax ID or TPIN #"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#taxWrapper" required/>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Collector</button> : <button className="btn btn-success btn-block">Create Collector</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Report</h4><p className="tx-13 tx-color-02 mg-b-0">Reported By: <span id="tx-user">---</span></p></div>
                                </div>
                                <div className="modal-body pd-sm-b-40 pd-sm-x-30">
                                    <p><strong>Description:</strong> <span id="tx-description">---</span></p>
                                    <p><strong>Type:</strong> <span id="tx-type">---</span></p>
                                    <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                    <p><strong>Status:</strong> <span id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                    {images.length > 0 ? <PhotoProvider>
                                        <p className="tx-primary">Click Photos Below</p>
                                        <div className="img-group float-left">
                                            {images.map((item, index) => (
                                                <PhotoView key={index} src={item}>
                                                    <img src={item} className="img wd-60 ht-60 rounded-circle" alt="" />
                                                </PhotoView>
                                            ))}
                                        </div>
                                    </PhotoProvider> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default Data;
