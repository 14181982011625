// const API_URL = 'http://127.0.0.1:3004/v1/merchant';
const API_URL = 'https://server.beautypay.co.zm/v1/merchant';

exports.SIGNIN_URL = `${API_URL}/merchant-sign-in`;
exports.UAC_URL = `${API_URL}/uac`;
exports.GET_COLLECTION_DATA = `${API_URL}/collection-data`;
exports.USER_URL = `${API_URL}/user`;
exports.MERCHANT_URL = `${API_URL}/merchant`;
exports.GET_USER_REPORT_URL = `${API_URL}/user-report`;
exports.GET_MEMBERSHIP_REPORT_URL = `${API_URL}/membership-report`;

exports.COUNTRIES_URL = `${API_URL}/get-countries`;
exports.COLLECTORS_URL = `${API_URL}/get-collectors`;
exports.COUNTRYS_URL =  `${API_URL}/get-countries`;
exports.SEARCH_COLLECTION_URL = `${API_URL}/search-collection`;
exports.GET_WASTE_REPORT_URL = `${API_URL}/waste-report`;
exports.GET_TERRITORIES = `${API_URL}/territories`;
exports.PRODUCT_URL = `${API_URL}/product`;
exports.MEDIA_URL = `${API_URL}/media`;
exports.GET_PRE_SIGNED_URL = `${API_URL}/generate-pre-signed-url`;
exports.GET_COLLECTION_WASTE_REPORT_URL = `${API_URL}/collection-wastes-report`;
exports.SUBURB_URL = `${API_URL}/suburb`;
exports.STATE_URL = `${API_URL}/state`;
exports.COUNTRY_URL = `${API_URL}/country`;
exports.COLLECTOR_URL = `${API_URL}/collector`;
exports.GET_ZONE_URL = `${API_URL}/zone`;
exports.TERRITORY_URL = `${API_URL}/territory`;
exports.ZONE_URL = `${API_URL}/zone`;
exports.NOTIFICATION_URL = `${API_URL}/notification`;
exports.WASTE_URL = `${API_URL}/waste`;
exports.CITY_URL = `${API_URL}/city`;
exports.FETCH_URL = `${API_URL}/image/`;
exports.GET_COLLECTOR_URL = (id) => `${API_URL}/collector/${id}`;
exports.GET_USER_URL = (id) => `${API_URL}/user/${id}`;
exports.TRANSACTION_URL = `${API_URL}/transaction`;
exports.SOCKET_URL = 'http://127.0.0.1:3002';
